.report-and-share {
    .ant-btn > .ant-btn-loading-icon .anticon {
        vertical-align: baseline;
    }
    .report-type-switcher {
        &.ant-radio-group  {
            width: 100%;
        }
        .ant-radio-button-wrapper {
            width: 50%;
            text-align: center;
            height: 50px;
            line-height: 50px;
        }
    }
    .report__item {
        margin-bottom: 24px;
        h3 {
            background-color: #323232!important;
            font-size: 16px;
            line-height: 50px;
            color: white;
            padding-left: 1em;
            margin-bottom: 1em;
        }
    }
    table tr th {
        background-color: #cccccc;
    }

    table tr th,
    table tr td { 
        border:1px solid #ddd;
        text-align: center;
    }
    .short-estimate-board {
        .ant-card-grid {
            box-shadow: none;
        }
    }
    .report__change-options {
        padding: 24px 12px;
        margin-bottom: 24px;
        background-color: #f0f0f0;
        .ant-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            .ant-checkbox-group-item {
                margin: 0 0 12px 0;
                flex-shrink: 1;
                flex-grow: 1;
                flex-basis: 25%;
            }
        }
    }
    .form-item-control-input {
        flex-basis: 100%;
        max-width: none;
    }
}