.upload {
    .ant-tabs {
        &-nav-wrap {
            flex-basis: 100%!important;
        }
        &-nav-list {
            flex-basis: 100%;
        }
        &-tab {
            flex-basis: 50%;
        }
    }
    .ant-input {
        border-radius: 16px;
    }
    .ant-upload {
        width: 100%;
        .ant-btn {
            border-radius: 16px;
            width: 100%;
            display: flex;
            align-items: baseline;
            justify-content: center;
        }
    }
}