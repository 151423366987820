$color-primary: #123963;
$color-danger: #FC304B;
$color-primary-blur: #01A0C6;
$color-foreign: #FCCE01;

$btn-color-primary: $color-primary-blur;
$btn-color-danger: #ff4d4f;

$ant__selector--wrapper-padding-tb: 5px;
$ant__selector--wrapper-height: 40px;
$ant__selector--wrapper-line-height: 38px;
$ant__form--item-height: 40px;
$ant__form--input-height: 20px;


@mixin screen-small {
    @media (max-width: 450px) {
        @content;
    }
}

@mixin screen-medium {
    @media (min-width: 450px) and (max-width: 767px) {
        @content;
    }
}

@mixin screen-lg {
    @media (min-width: 767px) {
        @content;
    }
}

@mixin screen-max-lg {
    @media (min-width: 1800px) {
        @content;
    }
}
