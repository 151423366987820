.subject-property {
    &__details {
        ul {
            list-style: none;
            padding: 0;
        }
        .flex-right {
            display: flex;
            flex-direction: column;
            justify-content: right;
        }
        .ant-btn-link  {
            border: none;
            background: none;
            padding: 0!important;
            span {
                font-size: 14px!important;
            }
        }
    }
}
.type-select-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    & > label {
        flex-basis: 25%;
        flex: 1;
        text-align: center;
    }
}

#report-google-map {
    width: 100%;
    padding-top: 300px;
}

.comparable-table {
    margin-top: 24px;
}