.fast-cma {
    &-profile-manager {
        .fast-cma-profile-actions {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            .fast-cma-add-new-profile {
                // border: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 32px;
                margin-right: 1em;
                span {
                    font-size: 14px!important;
                }
                .anticon-plus {
                    font-size: 0.75em!important;
                }
            }

        }
    }
    &-profile-remove-modal {
        .ant-btn {
            height: 32px;
        }
    }
}