
.fast-cma-title-wrapper {
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    a {
        font-size: 24px!important;
        text-decoration: none;
    }
    .ant-anchor-ink {
        display: none;
    }
    .ant-anchor-link {
        padding-left: 0;
    }
    .new-search-btn {
        margin-left: 20px;
        &,
        &:hover {
            background-color: #00a1c9;
            color: #fff
        }
        span {
            font-size: 14px!important;
        }
    }
}