.short-estimate-board .ant-card-grid {
  display: flex;
  flex-direction: column;
  color: #222222;
  font-size: 24px;
  .label {
    font-size: 14px;
    color: #222222;
  }
  padding-top: 0;
}