@import "variables";

$btn-blur: 0.7;

.ant-btn-primary {
    border-color: $btn-color-primary;
    background: $btn-color-primary;

    &:hover {
        color: white !important;
        border-color: rgba($btn-color-primary, $btn-blur) !important;
        background: rgba($btn-color-primary, $btn-blur) !important;
    }

    &:focus {
        color: white !important;
        border-color: rgba($btn-color-primary, $btn-blur) !important;
        background: rgba($btn-color-primary, $btn-blur) !important;
    }
}

.ant-btn-dangerous {
    border-color: $btn-color-danger;
    background: $btn-color-danger;

    &:hover {
        color: white !important;
        border-color: rgba($btn-color-danger, $btn-blur) !important;
        background: rgba($btn-color-danger, $btn-blur) !important;
    }

    &:focus {
        color: white !important;
        border-color: rgba($btn-color-danger, $btn-blur) !important;
        background: rgba($btn-color-danger, $btn-blur) !important;
    }
}

.ant-btn-dangerous {
    border-color: $btn-color-danger;
    background: $btn-color-danger;
}

.ant-btn {
    &:hover {
        color: $btn-color-primary;
        border-color: $btn-color-primary;
        background: #fff;
    }

    &:focus {
        color: $btn-color-primary;
        border-color: $btn-color-primary;
        background: #fff;
    }
}

.ant-menu-sub {
    background-color: white !important;
}

.ant-menu-dark .ant-menu-item > span > a {
    color: black;

    &:hover {
        color: $color-primary-blur;
    }
}

.ant-menu-item-selected {
    a {
        color: $color-primary-blur !important;
    }
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-primary-blur !important;
}

.ant-tabs-tab {
    &:hover {
        color: $color-primary-blur;
    }
}

.ant-tabs-ink-bar {
    color: $color-primary-blur !important;
    background: $color-primary-blur !important;
}

.ant-breadcrumb > span:last-child {
    color: $color-primary-blur;
}
