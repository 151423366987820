.estimate__block {
    display: flex;
    flex-direction: column;
    .ant-card-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
.statistic {
    &--selected {
        table tr th {
            background-color: #123963;
            color: #fff;
        }
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: #fcce01;
    }
    table tr td {
        padding: 0.5em 1em;
    }
}