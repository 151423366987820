@import "variables";

//.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//    color: $color-primary-blur !important;
//}

//.btn {
//    //border-radius: 5px;
//}
//
//.btn-primary {
//    background-color: $color-primary-blur !important;
//}

//.ant-btn {
//    //border-radius: 5px !important;
//}
//
//.ant-btn-primary {
//    border-color: $color-primary-blur;
//    background: $color-primary-blur;
//}

//.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
//    background-color: white !important;
//    //color: black !important;
//
//    a {
//        color: black;
//
//        &:hover {
//            color: $color-primary-blur !important;
//        }
//    }
//}
//
//.ant-menu-item-selected {
//    a {
//        color: $color-primary-blur !important;
//    }
//}

.hn {
    &__tabs-menu {
        .ant-tabs-tab {
            font-size: 18px;
        }

        .ant-tabs-nav {
            padding: 0 1rem !important;
        }
    }

    &__content {
        background-color: #FFFFFF;
    }

    &__navigation {
        z-index: 10;
        position: fixed;

        &--footer-powered {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-bottom: 70px;
            margin-left: 5px;

            a {
                font-size: 14px !important;
                color: #a7a7a7;
            }
        }

        .ant-menu.ant-menu-inline-collapsed {
            width: 0px;
        }

        .ant-menu {
            height: 100vh;
            background-color: white !important;
            -webkit-box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2) !important;
            box-shadow: 15px 6px 38px -11px rgba(0, 0, 0, 0.2) !important;
        }

        .ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
            color: black !important;
        }

        .ant-menu-submenu-arrow {
            color: black !important;
        }

        .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
            background-color: black !important;
        }

        .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
            background-color: black !important;
        }

        &--mobile {
            .ant-menu {
                height: 100vh;
                background-color: white;
            }

            .ant-drawer-header {
                background-color: white;
            }

            .ant-drawer-body {
                padding: 0;
            }

            .ant-drawer-close {
                color: white;
            }

            .ant-menu-title-content {
                color: black;
            }

            .ant-menu-submenu-arrow {
                &:after {
                    background: black !important;
                }

                &:before {
                    background: black !important;
                }
            }

            .ant-menu-item-selected {
                background-color: white !important;
                color: $color-primary-blur !important;
            }
        }
    }

    &__footer {
        height: 50px;
        padding: 10px 25px;
        background-color: white;
        //border-top: 1px solid #c1c1c1;
        box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.75);
        -webkit-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.75);
    }
}


@include screen-small {

}

@include screen-medium {

}

@include screen-lg {
    .ant-scrolling-effect {
        overflow: unset !important;
    }
    * {
        p {
            font-size: 18px !important;
        }

        a {
            font-size: 18px !important;
        }

        label {
            font-size: 18px !important;
        }

        .ant-popover-buttons {
            .ant-btn {
                span {
                    font-size: 14px !important;
                }
            }
        }

        .ant-btn {
            padding: 0 15px !important;

            span {
                font-size: 18px !important;
            }
        }
    }
    .ant-menu-vertical {
        background-color: #123963 !important;
    }
    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: unset !important;
    }
    .ant-menu-submenu-popup {
        //left: 230px !important;
    }
    .custom-table {
        .ant-table-cell {
            padding: 0 16px;
            font-size: 18px;
        }
    }
    .hn {
        &__content {
            //height: 100vh;
            transition-property: margin-left;
            transition-timing-function: cubic-bezier(.075, .82, .165, 1);
            transition-duration: .05s;
            //margin-left: 230px;
            //margin-top: 72px;
            padding-left: 10px;

            &--expanded {
                margin-left: 0px;
            }
        }

        &__navigation {

            &--mobile {
                display: none;
            }
        }
    }
}

@include screen-max-lg {
    .max {
        &__width {
            &--400px {
                width: 400px !important;
            }
        }
    }
}
