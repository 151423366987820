@import "../variables";

.fast-cma {
    &--icon-input-address {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 45px;
        margin-left: 25px;
        z-index: 99;
        font-size: 20px;
    }

    &--input-address {
        .ant-select-selector {
            padding-left: 40px !important;

            input {
                padding-left: 30px !important;
            }
        }
    }

    &-findings {
        &__item {
            $self: &;
            background-color: rgba(gray, 0.2);
            padding: 6px 0;

            &--title {
                color: gray;
            }

            p {
                color: gray;
            }

            &--active {
                background-color: rgba(#00A1C9, 0.2);

                #{$self}--title {
                    color: $color-primary-blur;
                }

                p {
                    color: $color-primary-blur;
                    font-weight: bold;
                }
            }
        }
    }
}

.hn {
    &__detail-cma {
        &--comparables {
            .ant-tabs-tab-btn {
                font-size: 12px !important;
            }
        }

        &--section-overview {
            .ant-collapse-arrow svg {
                transform: rotate(-90deg);
            }

            &-img {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                filter: blur(3px);
                -webkit-filter: blur(3px);

                &-blur {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    margin-top: 90px;
                    width: 200px;
                    font-weight: bold;
                    font-size: 25px !important;
                    text-align: center;
                    color: white;
                    z-index: 999;
                }
            }

            .ant-collapse-header {
                font-size: 16px;
                font-weight: 600;
            }

            border-radius: 10px;

            .ant-collapse-item {
                border-radius: 10px !important;

                .ant-collapse-header {
                    border-radius: 10px !important;
                    background-color: white;
                }

                .ant-collapse-content {
                    border-radius: 0 0 10px 10px !important;
                    background-color: white;
                }
            }

            &-size-info {
                position: relative;
                background-color: #F2F6F8;
                color: #6B6B6B;
                padding: 5px 10px;
                border-radius: 5px;
                font-weight: 500;
            }

            &-info {
                margin-bottom: 0;
                font-size: 12px !important;
                font-weight: 500;
                text-decoration: underline;
                cursor: pointer;
                color: #0D99FF;
                position: absolute;
                bottom: 0;
                left: 0;

                &:hover {
                    color: #0881D9
                }
            }
        }

        &--comparables-estimate {
            border-radius: 10px;
            padding: 10px 30px;
            color: rgba(154, 154, 154, 1);
            border: 2px solid rgba(154, 154, 154, 1);

            &-active {
                color: rgba(0, 161, 201, 1) !important;
                border: 2px solid rgba(0, 161, 201, 1) !important;
            }

            &-tag {
                padding: 5px 20px;
                border-radius: 10px;
                background-color: rgba(154, 154, 154, 0.1);

                &-active {
                    background-color: rgba(0, 161, 201, 0.1) !important;
                }
            }
        }

        &--table {
            .ant-tabs-tab-btn {
                font-size: 14px !important;
            }

            .ant-tabs-nav-wrap {
                justify-content: right;
            }

            .ant-table-row:has(.ant-checkbox-wrapper-disabled) {
                background-color: rgba(0, 161, 201, 0.1);
            }

            .ant-table-row-selected {
                background-color: transparent !important;
                border: 1px solid #00A1C9 !important;
            }

            .ant-table-tbody > tr.ant-table-row-selected > td {
                background-color: transparent !important;
                border-top: 1px solid #00A1C9 !important;
                border-bottom: 1px solid #00A1C9 !important;
            }

            .ant-table-tbody > tr.ant-table-row-selected > td:first-child {
                border-left: 1px solid #00A1C9 !important;
            }

            .ant-table-tbody > tr.ant-table-row-selected > td:last-child {
                border-right: 1px solid #00A1C9 !important;
            }

            .ant-checkbox-checked .ant-checkbox-inner::after {
                border-color: #00A1C9 !important;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: rgba(0, 161, 201, 0.1) !important;
                border-color: #00A1C9 !important;
            }

            .ant-checkbox-wrapper-disabled {
                position: relative;

                &:after {
                    position: absolute;
                    background-image: url("../img/verified.svg");
                    background-size: 23px 23px;
                    background-repeat: no-repeat;
                    top: 0;
                    right: 0;
                    width: 25px;
                    margin-right: -6px;
                    margin-top: -1px;
                }
            }
        }
    }
}

@include screen-small {
    .hn {
        &__fast-cma {

        }
    }
}

@include screen-medium {
    .hn {
        &__fast-cma {

        }
    }

}

@include screen-lg {

}
