
.find-comparables-form {
    
    .ant-form-vertical .ant-form-item-label > label,
    .ant-form-item-label>label {
        font-size: 14px!important;
    }
    .ant-input-number {
        width: 100%;
    }
    .ant-slider {
        margin-top: 0;
        margin-bottom: 24px;
    }
}