@import "./header";
@import "./layout";
@import "./pages/fastCMA";
@import "variables";
@import "antdOveride";

body > iframe {
    z-index: -1 !important;
}

.icon-ant {
    svg {
        margin-top: -5px !important;
    }
}

.cursorPointer {
    cursor: pointer;
}

.border {
    &__radius {
        @for $w from 1 through 20 {
            &--#{$w}px {
                border-radius: #{$w}px !important;
            }
        }
        @for $w from 1 through 100 {
            &--#{$w} {
                border-radius: #{$w}% !important;
            }
        }
    }
}

.color {
    &--main {
        color: $color-primary !important;
    }

    &--primary {
        color: $color-primary-blur !important;
    }

    &--white {
        color: white !important
    }

    &--black {
        color: black
    }

    &--description {
        color: rgba(0, 0, 0, 0.45)
    }

    &--foreign {
        color: $color-foreign;
    }

    &--red {
        color: red;
    }

    &--gray {
        color: rgba(0, 0, 0, 0.85);
    }

    &__ant-button {
        &--gray {
            span {
                color: rgba(177, 177, 177, 0.85) !important;
            }
        }
    }
}

.border-color {
    &--primary {
        border-color: $color-primary-blur !important;
    }

    &--white {
        border-color: white !important;
    }

    &--black {
        border-color: black
    }

    &--foreign {
        border-color: $color-foreign;
    }
}

.bg {
    &--primary {
        background-color: $color-primary-blur !important;
    }

    &--white {
        background-color: white
    }

    &--black {
        background-color: black
    }

    &--foreign {
        background-color: $color-foreign;
    }
}

.btn-bg {
    &--white {
        border-color: unset;
        background-color: white;

        &:hover {
            border-color: unset !important;
            background-color: rgba(white, 0.6) !important;
        }
    }

    &--black {
        border-color: unset;
        background-color: black;

        &:hover {
            border-color: unset !important;
            background-color: rgba(black, 0.6) !important;
        }
    }

    &--foreign {
        border-color: unset;
        background-color: $color-foreign;
        box-shadow: unset !important;

        &:hover {
            border-color: unset !important;
            background-color: rgba($color-foreign, 0.6) !important;
            box-shadow: unset !important;
        }

        &:active {
            border-color: unset !important;
            background-color: rgba($color-foreign, 0.6) !important;
            box-shadow: unset !important;
        }

        &:focus {
            border-color: unset !important;
            background-color: rgba($color-foreign, 0.6) !important;
            box-shadow: unset !important;
        }
    }

    &--primary {
        border-color: unset;
        background-color: $color-primary-blur;
        box-shadow: unset !important;

        &:hover {
            border-color: unset !important;
            background-color: rgba($color-primary-blur, 0.6) !important;
            box-shadow: unset !important;
        }

        &:active {
            border-color: unset !important;
            background-color: rgba($color-primary-blur, 0.6) !important;
            box-shadow: unset !important;
        }

        &:focus {
            border-color: unset !important;
            background-color: rgba($color-primary-blur, 0.6) !important;
            box-shadow: unset !important;
        }
    }
}

.mb {

    //&--14px{
    //    font-size: 14px !important;
    //}
    //&--12px{
    //    font-size: 12px !important;
    //}
    @for $f from 1 through 40 {
        &--s#{$f}px {
            margin-bottom: -#{$f}px !important;
        }
    }

    @for $f from 1 through 40 {
        &--#{$f}px {
            margin-bottom: #{$f}px !important;
        }
    }
}

.mt {

    //&--14px{
    //    font-size: 14px !important;
    //}
    //&--12px{
    //    font-size: 12px !important;
    //}
    @for $f from 1 through 40 {
        &--s#{$f}px {
            margin-top: -#{$f}px !important;
        }
    }

    @for $f from 1 through 40 {
        &--#{$f}px {
            margin-top: #{$f}px !important;
        }
    }
}

.button-color {
    &--transparent {
        label {
            color: transparent !important;
        }
    }
}

.width {
    @for $w from 1 through 500 {
        &-#{$w}px {
            width: #{$w}px !important;
        }
    }
    @for $w from 1 through 500 {
        &--#{$w}px {
            width: #{$w}px !important;
        }
    }

    @for $w from 1 through 500 {
        &-#{$w}rem {
            width: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &-#{$w} {
            width: #{$w}% !important;
        }
    }

    //&-90{
    //    width: 90% !important;
    //}
}

.height {
    @for $w from 1 through 500 {
        &--#{$w}px {
            height: #{$w}px !important;
        }
    }

    @for $w from 1 through 500 {
        &--#{$w}rem {
            height: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w} {
            height: #{$w}% !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w}vw {
            height: #{$w}vw !important;
        }
    }
    //&-90{
    //    width: 90% !important;
    //}
}

.max-width {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            max-width: #{$w}px !important;
        }
    }

    @for $w from 1 through 2000 {
        &--#{$w}rem {
            width: #{$w}rem !important;
        }
    }

    @for $w from 1 through 100 {
        &--#{$w} {
            width: #{$w}% !important;
        }
    }
    //&-90{
    //    width: 90% !important;
    //}
}

.max-height {
    @for $w from 1 through 2000 {
        &--#{$w}px {
            max-height: #{$w}px !important;
        }
    }
}

.fs {
    @for $f from 1 through 100 {
        &--#{$f}px {
            font-size: #{$f}px !important;
        }
    }

    @for $f from 1 through 100 {
        &--#{$f}rem {
            font-size: #{$f}rem !important;
        }
    }
}

.fw {
    @for $f from 1 through 1000 {
        &--#{$f} {
            font-weight: #{$f} !important;
        }
    }
}

.m {
    &-l {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-left: #{$w}px !important;
            }
        }
        @for $w from 1 through 500 {
            &--s#{$w}px {
                margin-left: -#{$w}px !important;
            }
        }
        @for $w from 1 through 100 {
            &-#{$w} {
                margin-left: #{$w}% !important;
            }
        }
    }

    &-r {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-right: #{$w}px !important;
            }
        }
        @for $w from 1 through 500 {
            &--s#{$w}px {
                margin-right: -#{$w}px !important;
            }
        }
        @for $w from 1 through 100 {
            &-#{$w} {
                margin-right: #{$w}% !important;
            }
        }
    }

    &-t {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-top: #{$w}px !important;
            }
        }
    }

    &-x {
        @for $w from 1 through 500 {
            &--#{$w}px {
                margin-left: #{$w}px !important;
                margin-right: #{$w}px !important;
            }
        }
    }

}

.fs-btn-antd {
    span {
        @for $f from 1 through 100 {
            &--#{$f}px {
                font-size: #{$f}px !important;
            }
        }

        @for $f from 1 through 100 {
            &--#{$f}rem {
                font-size: #{$f}rem !important;
            }
        }
    }
}

.cursorPointer {
    cursor: pointer;
}

body {
    width: 100% !important;
    //overflow: hidden;
}

.filter-component {
    .ant-form-item {
        margin-bottom: 8px;
    }
}

@include screen-small {
}

@include screen-medium {
    .width__lg {
        @for $w from 1 through 500 {
            &--#{$w}px {
                width: #{$w}px !important;
            }
        }

        @for $w from 1 through 500 {
            &--#{$w}rem {
                width: #{$w}rem !important;
            }
        }

        @for $w from 1 through 100 {
            &--#{$w} {
                width: #{$w}% !important;
            }
        }

        //&-90{
        //    width: 90% !important;
        //}
    }
}

@include screen-lg {
    .width__lg {
        @for $w from 1 through 500 {
            &--#{$w}px {
                width: #{$w}px !important;
            }
        }

        @for $w from 1 through 500 {
            &--#{$w}rem {
                width: #{$w}rem !important;
            }
        }

        @for $w from 1 through 100 {
            &--#{$w} {
                width: #{$w}% !important;
            }
        }

        //&-90{
        //    width: 90% !important;
        //}
    }
}

@include screen-max-lg {
}

.box--shadow {
    -webkit-box-shadow: 1px 1px 9px -4px #000000;
    box-shadow: 1px 1px 9px -4px #000000;
}

.symbol {
    &--vertical {
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            //left: 0;
            right: 0;
            margin-right: -15px !important;
            margin: auto;
            content: "";
            //border-right: 2px solid #C4C4C4;
            //height: 55%;
            width: 1px;
            background-color: #C4C4C4 !important;
            //height: 55%;
            display: block;
        }
    }
}

.custom {

    &--form {
        .ant-form-item-label {
            label {
                font-size: 14px !important;
            }
        }
    }

    &--icon {
        svg {
            margin-top: -5px !important;
        }
    }

    &--selector {

        &-hide-check {
            .ant-select-item-option-state {
                display: none;
            }
        }

        .ant-select-selector {
            border-radius: $ant__selector--wrapper-padding-tb !important;
            min-height: $ant__selector--wrapper-height !important;
            overflow-y: scroll;
        }

        .ant-select-selection-item {
            line-height: $ant__selector--wrapper-line-height !important;
        }

        .ant-select-selection-overflow-item .ant-select-selection-item {
            line-height: 23px !important;

            svg {
                margin-top: -3px;
            }
        }
    }

    &--auto-complete {
        .ant-select-selector {
            padding-top: 3px !important;
            border-radius: $ant__selector--wrapper-padding-tb !important;
            min-height: $ant__selector--wrapper-height !important;
        }

        .ant-select-selection-search {
            input {
                min-height: $ant__selector--wrapper-height !important;
                line-height: $ant__selector--wrapper-height !important;
                padding-top: 5px;
            }
        }
    }

    &--input {
        .ant-form-item-control-input-content {
            padding-top: 3px !important;
            border-radius: $ant__selector--wrapper-padding-tb !important;
            min-height: $ant__selector--wrapper-height !important;
        }

        .ant-input-number-affix-wrapper {
            border-radius: 10px;

            .ant-input-number {
                border-radius: 10px;
            }

            input {
                min-height: 30px !important;
                border-radius: $ant__selector--wrapper-padding-tb !important;
                line-height: 30px !important;
                padding-top: 0 !important;
            }
        }

        .ant-form-item-control-input-content {
            input {
                min-height: 30px !important;
                border-radius: $ant__selector--wrapper-padding-tb !important;
                line-height: 30px !important;
                padding-top: 5px;
            }
        }
    }

    &--button {
        min-height: $ant__selector--wrapper-line-height !important;
    }
}
