.comparable {
    margin-top: 24px;
    line-height: 1.25em;
    &__select-item {
        width: 100%;
    }
    &__select-item,
    &__table {
        th, td {
            padding: 1em 0.25em 1em 1em;
        }
    }

    &__table-wrapper {
        width: 100%;
        overflow-x: scroll;
    }

    &__head {
        text-align: left;
        vertical-align: middle;
        background-color: #60a62c;
        border-right: 2px solid #f0f0f0;
        position: -webkit-sticky!important; /* for Safari */
        position: sticky!important;
        left: 0;
        z-index: 50;
        ul {
            list-style: none;
            padding-left: 0;
        }
        .property {
            li {
                margin-bottom: 1em;
            }
            &__label {
                height: 2.5em;
                display: flex;
                align-items: center;
                margin-bottom: 1em;
            }
            &__sub {
                padding: 0 0 0 1.25em;
            }
        }
    }
    &__row {
        &--has-children > th {
            position: relative;
            &:after {
                content: "+";
                display: block;
                position: absolute;
                width: 1em;
                height: 1em;
                line-height: 1em;
                text-align: center;
                right: 4px;
                transform: translate(0, -50%);
                top: 50%;
                font-weight: bold;
                background: #333;
                color: #fff;
                cursor: pointer;
                border-radius: 2px;
            }
        }

        &--has-children + tbody &--child {
            display: none;
        }

        &--has-children.show-child + tbody &--child {
            display: table-row;
        }
        &--has-children.show-child > th::after {
            content: "-"
        }
        &--child th {
            padding-left: 1.5em
        }
        &:hover td {
            background-color: #fcce01;
            color: #333;
        }
    }
    &__cell {
        text-align: center;
        vertical-align: text-top;
        width: 200px;
        border-right: 2px solid #f0f0f0;
        &:last-child {
            border-right: none;
        }
        > span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        table {
            width: 100%;
            tr {
                display: flex;
                flex-direction: column;
            }
        }
        &--subject {
            background-color: #fcce01;
            min-width: 170px;
            position: -webkit-sticky; /* for Safari */
            position: sticky;
            left: 172px;
            z-index: 98;
        }
        &--selected {
            background-color: #60a62c;
            color: white;
            .ant-checkbox-wrapper {
                color: #fff;
            }
        }
    }
    .sub-properties &__cell > span {
        height: auto;
    }

    &__overview {
        margin-top: 24px;
        height: 300px;
    }

    &__type {
        height: 100%;
        .ant-card {
            background-color: hsl(0, 0%, 60%);
            color: #ffffff;
            height: 50%;
            &-head {
                &-title {
                    color: #fff;
                }
            }
            &-extra {
                color: #fff;
            }
            .money {
                font-size: 1.25em;
                line-height: 1.5em;
                font-weight: bold;
            }
            .ant-row {
                flex-flow: row nowrap;
                font-size: 12px;
            }
        }
        &--active .ant-card {
            height: 100%;
            background-color: #396c05;
        }
    }
    .ant-tabs {
        &-nav-list {
            .anticon {
                margin-right: 0.125em;
                vertical-align: baseline;
            }
        }
    }
}